.App {
  text-align: left;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 1vmin);
  color: #1b1b1b;
  margin: 50px;
  white-space: pre-wrap;
  max-width: 800px;
  word-wrap: break-word;
  width: calc(100vw - 100px);
}

p {
  margin: 0;
}



@keyframes blink {
  50% {
    border-color: transparent;
  }
}
